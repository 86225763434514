import { gql } from "@apollo/client";
import { client } from "./client";

export const DOOR_CONFIGURATION_LIST = gql`
  query {
    doorConfiguratorList {
      doorConfiguratorList
    }
  }
`;

export const FULLDOOR_CONFIGURATOR = gql`
  query getFullDoorConfigurator($id: ID!) {
    fullDoorConfigurator(id: $id) {
      parentId
      id
      title
      artNum
      artId
      width
      height
      length
      drehrichtung
      picture
      type
      kantenausfuehrung
    }
  }
`;

export function getAllConfigs(ids, callback) {
  client
    .query({
      query: gql`
      query getFullDoorConfigurator {
        ${ids.map(
          (id, i) => `
          query${i}: fullDoorConfigurator(id: "${id}") {
            parentId
            id
            title
            artNum
            artId
            width
            height
            length
            drehrichtung
            picture
            type
            kantenausfuehrung
          }`
        )}
    }`,
    })
    .then((data) => {
      callback(data.data);
    });
}
