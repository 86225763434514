import * as React from "react";
import { SUGGEST_CATEGORIES_TRANSLATION } from "../search/utils";

import Suggestion from "./Suggestion";

function SuggestionsLayer({ suggestions, query }) {
  const isVisible = Object.values(suggestions).filter(
    (value) => value.length !== 0
  )?.length > 0;

  return (
    <ul className={`suggestions${!isVisible ? " d-none" : ''}`}>
      {Object.entries(suggestions).map(
        ([key, entries]) =>
          entries.length > 0 && (
            <>
              {SUGGEST_CATEGORIES_TRANSLATION[key] && (
                <li className="suggestions-item suggestions-headline">
                  <span>{SUGGEST_CATEGORIES_TRANSLATION[key]}</span>
                </li>
              )}
              {entries.map((suggestion) => (
                <Suggestion
                  key={suggestion}
                  query={query}
                  id={key}
                  {...suggestion}
                />
              ))}
            </>
          )
      )}
    </ul>
  );
}

export default SuggestionsLayer;
