export const TYPE_INDEX = {
  door: 0,
  frame: 1,
  "door handle": 2,
};

export const TYPE_TITLE= {
  door: 'Tür',
  frame: 'Zarge',
  "door handle": "Türgriff",
};
