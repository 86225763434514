import * as React from "react";

import { TYPE_INDEX } from "../utils";

function DropDown({
  data,
  title,
  activeConfiguration,
  setActiveConfiguration,
  disabled,
  fetchArticle,
}) {
  if (!data || data.length === 0) return null;

  const handleClick = (e, item) => {
    e.preventDefault();
    // close dropdown after click
    const dropdown = document.getElementsByClassName(
      "btn-group dropdown show"
    )?.[0];
    dropdown?.classList.remove("show");
    dropdown?.childNodes[1].classList.remove("show");

    fetchArticle(item.artId, TYPE_INDEX[item.type]);
    setActiveConfiguration((previousConfiguration) => ({
      ...previousConfiguration,
      [item.type]: item,
      }));
  };
  
  return (
    <div className="configurator__filter">
      <span className="configurator__dropdown-title">{title}</span>
      <div className="btn-group dropdown">
        <button
          className={`borderBtn font--normal dropdown-toggle${
            disabled ? " btn-disabled" : ""
          }`}
          disabled={disabled}
          data-toggle="dropdown"
          data-filter-name={title}
        >
          {activeConfiguration?.[data[0].type]?.title || activeConfiguration?.[data[0].type]?.artId || "Auswählen"}
        </button>
        <ul class="dropdown-menu" role="menu" data-filter-name={title}>
          {data.map((item) => {
            return (
              <li className="p-3 filter-item">
                <button className="btn btn-default" onClick={(e) => handleClick(e, item)}>
                  {item.title || item.artId}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default DropDown;
