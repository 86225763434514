import * as React from "react";
import ReactDOM from "react-dom/client";

import SuggestionsLayer from "../suggestions/SuggestionsLayer";

const searchLayerNode = document.getElementById("searchlayer");
const searchLayer = searchLayerNode && ReactDOM.createRoot(searchLayerNode);

function Search() {
  const [suggestions, setSuggestions] = React.useState();
  const [query, setQuery] = React.useState();

  async function handleSubmit(e) {
    const value = e.target?.value;
    const baseUrl = document.body.dataset.baseurl;

    const result = await fetch(
      `${baseUrl}/?cl=suggest_search&searchparam=${value}`
    );
    const res = await result.json();
    let adjustedSuggestions = { brand: [], category: [], productName: [] };

    if (res.suggestions?.length > 0) {
      res.suggestions?.forEach((item) => {
        adjustedSuggestions[item.type] = adjustedSuggestions[item.type]
          ? [...adjustedSuggestions[item.type], item]
          : [item];
      });
    }

    setSuggestions(adjustedSuggestions);
    setQuery(value);
  }

  React.useEffect(() => {
    const input = document.querySelector(
      "#ffsearch + form .searchContainer__input"
    );
    input.addEventListener("input", handleSubmit);

    return () => {
      input.removeEventListener("input", handleSubmit);
    };
  }, []);

  return (
    suggestions &&
    searchLayer.render(
      <React.StrictMode>
        <SuggestionsLayer suggestions={suggestions} query={query} />
      </React.StrictMode>
    )
  );
}

export default Search;
