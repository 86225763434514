import * as React from 'react'

import DropDownContainer from "./dropdown/DropDownContainer";
import Images from "./image/Images";

function ConfiguratorView({ activeImage, setActiveImage, ...props }) {
  return (
    <div className="row productDetails configurator--productDetails">
      <div className="col-12 col-lg-6">
        <div className="productImages">
          <div className="productImages__main">
            <div className="embed-responsive embed-responsive-1by1">
              <div className="embed-responsive-item">
                <img
                  className={`configurator--product-${
                    activeImage?.active && !activeImage?.active.includes('placeholder') ? "image" : "placeholder"
                  }`}
                  src={activeImage?.active || "../../../../out/zeg/img/placeholder-image.png"}
                />
              </div>
            </div>
          </div>
        </div>
        <Images
          activeImage={activeImage}
          setActiveImage={setActiveImage}
          keys={Object.keys(props.data)}
        />
      </div>
      <div className="col-12 col-lg-6">
        <div className="productInfo">
          <h2 class="configurator__headline--configurator">
            Türenkonfigurator
          </h2>
          <p>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
            et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
            Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
            sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
            et dolore magna aliquyam erat, sed diam voluptua. At vero eos et
            accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
            no sea takimata sanctus est Lorem ipsum dolor sit amet.
          </p>
          <div className="row configurator__dropdown-container">
            <DropDownContainer {...props} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfiguratorView;
