import * as React from "react";
import reactStringReplace from "react-string-replace";

function Suggestion({ name, query, attributes, hitCount, searchParams }) {
  const paramValues = searchParams?.filters?.[0]?.values?.[0].value;
  // FactFinder has some weird expectations, so some characters must be url encoded twice
  const mapObj = {
    '%': "%25",
    '/': "%2F",
    '=': "%3D",
    '|': "%7C",
    '+': "%2B",
    '#': "%23"
  };
  let value = [];
  if (paramValues !== undefined && paramValues !== null) {
    if (!Array.isArray(paramValues)) {
      value.push(paramValues);
    } else {
      value = paramValues;
    }

    value.forEach((element, index) => {
      Object.entries(mapObj).forEach(entry => {
        const [search, replace] = entry;
        value[index] = value[index].replaceAll(search, replace);
      });
    });
  }

  const filterValues = value && value.length > 0 ? value.join("/") : value;
  const encodeName = encodeURIComponent(name);
  const filterName = attributes?.sourceField || searchParams?.filters?.[0].name;
  const encodeFilterValue = encodeURIComponent(filterValues);

  let link = attributes?.Deeplink ? `${attributes?.Deeplink}?` : `?lang=0&cl=search&searchparam=${encodeName}`;

  // only add filter-params when they are defined/set otherwise you get: 'filter=undefined:undefined'
  if (filterName !== '' && (`${filterName}` !== 'undefined' || `${encodeFilterValue}` !== 'undefined')) {
    let filterParams = [];
    filterParams.push(`&filter=`);

    if (`${filterName}` !== 'undefined') {
      filterParams.push(`${filterName}`);
    }
    if (`${filterName}` !== 'undefined' && `${encodeFilterValue}` !== 'undefined') {
      filterParams.push(`%3A`);
    }
    if (`${encodeFilterValue}` !== 'undefined') {
      filterParams.push(`${encodeFilterValue}`);
    }

    link += filterParams.join('');
  }

  const parentCategories = attributes?.parentCategory?.split("/").join(" > ");

  return (
    <li className="suggestions-item">
      <a href={link}>
        {reactStringReplace(name, query, (match, i) => (
          <span key={`${match}-${i}`} className="suggestions-item--highlight">
            {match}
          </span>
        ))}
        {parentCategories && (
          <span className="parentcategory">
            {decodeURIComponent(parentCategories)}
          </span>
        )}
        {!!hitCount && (
          <span className="amount">
            {hitCount === 1 ? "Ein Produkt" : `${hitCount} Produkte`}
          </span>
        )}
      </a>
    </li>
  );
}

export default Suggestion;
