/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import {
  FULLDOOR_CONFIGURATOR,
  DOOR_CONFIGURATION_LIST,
  getAllConfigs,
} from "../apollo/query";
import ConfiguratorView from "./ConfiguratorView";
import { TYPE_TITLE } from "./utils";
import DropDownContainer from "./dropdown/DropDownContainer";

function Configurator({ id, isSingleConfig }) {
  const { data: dataArticleIds, loading } = useQuery(DOOR_CONFIGURATION_LIST);
  const [getConfig, { data }] = useLazyQuery(FULLDOOR_CONFIGURATOR);
  const [allConfigData, setAllConfigData] = React.useState();
  const [configData, setConfigData] = React.useState();
  const [activeConfiguration, setActiveConfiguration] = React.useState();
  const [activeImage, setActiveImage] = React.useState();

  const fetchArticle = async (id, typeIndex) => {
    const url = `/widget.php?cl=ConfiguratorProductWidget&anid=${id}`;
    const data = await fetch(url);
    const html = await data.text();

    const container = document.getElementById("configurator__articles");
    container?.closest(".container.d-none")?.classList.remove("d-none");

    if (container && html) {
      const wrapper = container.childNodes[typeIndex]
        ? container.childNodes[typeIndex]
        : document.createElement("div");
      wrapper.className = "col-12 col-lg-4";
      wrapper.innerHTML = html;

      const imgSrc = wrapper.getElementsByTagName("img")?.[0]?.src;

      let newSources = activeImage?.sources || [];
      newSources[typeIndex] = imgSrc;

      const newActiveImageSrc = {
        active: !activeImage?.active ? imgSrc : activeImage?.active,
        sources: newSources,
      };
      container.appendChild(wrapper);
      setActiveImage(newActiveImageSrc);

      const event = new Event("zeg.dominserted", { bubbles: true });
      document.body.dispatchEvent(event);
    }
  };

  const filterDataByType = (type, array, result) => {
    const filteredArray = array.filter((item) => item.type === type);
    return result ? [...result, ...filteredArray] : filteredArray;
  };

  const sortConfigData = (result, curData) => {
    const array = curData.fullDoorConfigurator || curData;

    result[0] = filterDataByType("door", array, result[0]);
    result[1] = filterDataByType("door handle", array, result[1]);
    result[2] = filterDataByType("frame", array, result[2]);

    return result;
  };

  React.useEffect(() => {
    if (dataArticleIds?.doorConfiguratorList) {
      const match =
        dataArticleIds.doorConfiguratorList.doorConfiguratorList.find(
          (articleId) => articleId === parseInt(id, 10)
        );

      if (!isSingleConfig) {
        getAllConfigs(
          dataArticleIds.doorConfiguratorList.doorConfiguratorList,
          setAllConfigData
        );
      }

      if (match && isSingleConfig) {
        getConfig({ variables: { id: parseInt(id, 10) } });
      }
    }
  }, [dataArticleIds]);

  React.useEffect(() => {
    if (data || allConfigData) {
      let result = [];

      if (allConfigData) {
        Object.values(allConfigData).forEach((value) => {
          result = sortConfigData(result, value);
        });
      } else {
        result = sortConfigData(result, data);
      }

      const merged = [
        { title: TYPE_TITLE[result[0][0].type], data: result[0] },
        { title: TYPE_TITLE[result[2][0].type], data: result[2] },
        { title: TYPE_TITLE[result[1][0].type], data: result[1] },
      ];

      setConfigData(merged);
    }
  }, [data, allConfigData]);

  const filterFrame = (curData) => {
    return curData.filter(
      (item) =>
        item.type === "frame" &&
        item.width === activeConfiguration?.door?.width &&
        item.length === activeConfiguration?.door?.length &&
        item.drehrichtung === activeConfiguration?.door?.drehrichtung
    );
  };

  React.useEffect(() => {
    if (activeConfiguration?.door) {
      let frame;

      if (allConfigData) {
        Object.values(allConfigData).forEach((value) => {
          frame = frame
            ? [...frame, ...filterFrame(value)]
            : filterFrame(value);
        });
      } else {
        frame = filterFrame(data.fullDoorConfigurator);
      }

      let newIndex;
      configData.forEach((item, i) => {
        if (item.title === TYPE_TITLE[frame[0].type]) newIndex = i;
      });

      const filteredConfigData = configData;

      filteredConfigData[newIndex] = {
        title: TYPE_TITLE[frame[0].type],
        data: frame,
      };

      setConfigData(filteredConfigData);
    }
  }, [activeConfiguration?.door]);

  const Component = React.useMemo(
    () => (isSingleConfig ? DropDownContainer : ConfiguratorView),
    [isSingleConfig]
  );

  return (
    !loading &&
    configData && (
      <Component
        data={configData}
        activeConfiguration={activeConfiguration}
        setActiveConfiguration={setActiveConfiguration}
        fetchArticle={fetchArticle}
        isSingleConfig={isSingleConfig}
        activeImage={activeImage}
        setActiveImage={setActiveImage}
      />
    )
  );
}

export default Configurator;
