import * as React from 'react'

function Image({ src, handleClick }) {
    const curSrc = src || "../../../../out/zeg/img/placeholder-image.png";

  return (
    <button onClick={() => handleClick(curSrc)} className="image-wrapper">
      <img
        className={`configurator--product-${src ? "image" : "placeholder"}`}
        src={curSrc}
      />
    </button>
  );
}

export default Image;
