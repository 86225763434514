import * as React from 'react'

import Image from "./Image";

function Images({ activeImage, keys, setActiveImage }) {
  const handleClick = (src) => {
    setActiveImage({ active: src, sources: activeImage.sources });
  };

  return (
    <div className="images-wrapper">
      {keys.map((_, index) => (
        <Image src={activeImage?.sources?.[index]} handleClick={handleClick} />
      ))}
    </div>
  );
}

export default Images;
