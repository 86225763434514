import * as React from 'react'

import DropDown from "./DropDown";

function DropDownContainer({
  data,
  activeConfiguration,
  setActiveConfiguration,
  fetchArticle,
  isSingleConfig,
}) {
  function render(children) {
    if (!isSingleConfig) {
      return <div className="col-12 col-lg-6">{children}</div>;
    }

    return children;
  }

  return data.map(({ data: dropdownData, title }, index) => {
    const activeLength = activeConfiguration && Object.keys(activeConfiguration);

    return render(
        <DropDown
          data={dropdownData}
          title={title}
          activeConfiguration={activeConfiguration}
          setActiveConfiguration={setActiveConfiguration}
          fetchArticle={fetchArticle}
          disabled={index !== 0 && (!activeLength || activeLength?.length < index)}
        />
    )}
  );
}

export default DropDownContainer;
