import React from "react";
import ReactDOM from "react-dom/client";
import { ApolloProvider } from "@apollo/client";
import Configurator from "./components/Configurator";
import Search from "./components/search/Search";
import { client } from "./apollo/client";

const node = document.getElementById("configurator");
const nodeGeneral = document.getElementById("generalConfigurator");
const root = node && ReactDOM.createRoot(node);
const generalRoot = nodeGeneral && ReactDOM.createRoot(nodeGeneral);
const dataId = node?.dataset?.id;

const search = document.getElementById('ffsearch');
const searchRoot = search && ReactDOM.createRoot(search);

if (dataId) {
  root.render(
    <React.StrictMode>
      <ApolloProvider client={client}>
        <Configurator id={dataId} isSingleConfig />
      </ApolloProvider>
    </React.StrictMode>
  );
}

if (searchRoot) {
  searchRoot.render(
    <React.StrictMode>
        <Search />
    </React.StrictMode>
  );
}

generalRoot?.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Configurator />
    </ApolloProvider>
  </React.StrictMode>
);
